@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../font/normal-300.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../font/normal-400.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../font/normal-600.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../font/normal-700.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../font/normal-800.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html,body { font-family: 'Open Sans', sans-serif; font-size: 15px; color: #444444;  }
p { margin-bottom: 0rem !important; }
a { color: #444444 !important; text-decoration: none !important; }
.color-blue { color: #2563c3 !important; }

.info-block { position: relative; display: flex; min-width: 12rem;}
.info-block .info-icon { display: flex; }
.info-block .info-icon i { margin: auto 0.7rem auto auto; font-size: 2.2rem; }
.info-block .info-details p { font-size: 1rem; font-weight: 700; }
.info-block .info-details span { font-size: 0.9rem !important; color: #666666; } 

.title-block { margin: 2.8rem 0rem; }
.title-block p {font-size: 1.3rem; line-height: 2rem; font-weight: 600 !important; }
.title-block .title-line { width: 4rem; height: 4px; background-color: #444444; margin-top: 1rem; }

.content-block { max-width: 50rem; margin: auto; }
#body { padding: 3rem 0rem; background-color: #ffffff; height: calc(100vh - 65px); min-height: 50rem; }
.body-logo-block { 
    position: relative; width: 8rem; height: 8rem; border: 1px solid #efefef; box-shadow: 0px 5px 8px #eaeaea; background-color: #ffffff; margin: auto; border-radius: 7px;
}
.body-logo-block img { position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; width: 100%; border-radius: 7px;  }

.app-icon-block { margin: 1rem 0.5rem; display: flex; }
.app-icon-block img { max-width: 15rem; height: 4rem; margin: auto; }

#copy-right-footer {padding: 1.2rem 0rem; background-color: #f5f5f5; }
.sellon-logo { width: 5rem; height: auto; margin-left: 0.5rem; }

@media screen and (max-width: 1200px) {
    #body { height: auto; }
}